import { render, staticRenderFns } from "./DatabaseProperties.vue?vue&type=template&id=5ee643d6"
import script from "./DatabaseProperties.vue?vue&type=script&lang=js"
export * from "./DatabaseProperties.vue?vue&type=script&lang=js"
import style0 from "./DatabaseProperties.vue?vue&type=style&index=0&id=5ee643d6&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "DatabaseProperties.vue"
export default component.exports